@font-face { font-family: "IBM Plex"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Regular.ttf');
} 

@font-face { font-family: "IBM Plex Bold"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Bold.ttf');
} 

@font-face { font-family: "IBM Plex Sans Italic"; 
  src: url('../../assets/font/IBM/IBMPlexSans-LightItalic.ttf');
} 

@font-face { font-family: "IBM Plex Sans medium"; 
  src: url('../../assets/font/IBM/IBMPlexSans-MediumItalic.ttf');
} 

.alinha{
  
}

.alinha2{
  display: flex;
  justify-content: flex-end;
}

.DescCampo {
  font-family: "IBM Plex Sans Italic";
  font-size: 14pt;
}

.Value{
  font-size: 18pt;
}

.Value2{
  font-family: "IBM Plex Bold";
  font-size: 18pt;
}

.divTitle {
  background-color: #D9D9D9;
  color: #424242;
  height: 56px;
  width: 100%;
  padding: 1rem 1rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  margin-bottom: 0.5rem;
}

.divTitle b {
  font-family: "IBM Plex Bold";
}