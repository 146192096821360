.containerSemResultados {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1rem;
  padding: 2rem 0;
}
.containerSemResultados svg {
  height: 40px;
  width: 40px;
  color: #8a8a8a;
}
.containerSemResultados h1 {
  font-weight: 400;
  max-width: 26%;
  color: #8a8a8a;
  font-size: 1.1rem;
  text-align: center;
}
@media (max-width: 1000px) {
  .containerSemResultados h1 {
    max-width: 45%;
  }
}
@media (max-width: 600px) {
  .containerSemResultados h1 {
    max-width: 80%;
  }
}
