@font-face { font-family: "IBM Plex Sans"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Regular.ttf');
} 

@font-face { font-family: "IBM Plex Bold"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Bold.ttf');
} 

@font-face { font-family: "IBM Plex Sans Italic"; 
  src: url('../../assets/font/IBM/IBMPlexSans-LightItalic.ttf');
} 

@font-face { font-family: "IBM Plex Sans medium"; 
  src: url('../../assets/font/IBM/IBMPlexSans-MediumItalic.ttf');
} 

.containerFiltroData {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 0 auto;
  max-width: 1360px;
}

@media (max-width: 1380px) {
  .containerFiltroData {
    max-width: 1380px;
    margin: 1rem 4rem 0 4rem;
  }
}

.divTitle {
  background-color: #D9D9D9;
  color: #424242;
  height: 56px;
  width: 100%;
  padding: 1rem 1rem;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0;
  margin-bottom: 0.5rem;

  font-family: "IBM Plex Bold";
}

.divTitle b {
  font-family: "IBM Plex Bold";
}

.boxCard, .boxTable {
  background-color: #FFFFFF;
  font-family: "IBM Plex Sans medium";
  padding: 0 !important;

  min-height: 500px;
  max-height: 493px;
}

.boxTable {
  height: 600px;
}

.boxResumo {
  background-color: #FFFFFF;
  font-family: "IBM Plex Sans medium";
  padding: 0 !important;
  margin: 0;
}

@media (max-width: 1366px) {
  .boxCard, .boxTable {
    min-height: 400px;
  }

  .boxTable {
    height: 400px;
  }

  .boxTableSize {
    height: 320px;
    min-height: 320px;
    overflow-y: auto;
    margin-bottom: 10;
  }
}

@media (min-width: 1450px) {
  .boxTableSize {
    height: 328px;
    min-height: 328px;
  }
}

@media (max-width: 550px) {
  .boxCard {
    min-height: 560px;
    max-height: 570px;
    margin-bottom: 10px;
  }
  
  .fBtn{
    margin-bottom: 10px;
  }

  .RPedido{
    padding-left: 0 !important
  }

  .btnProd{
    justify-content: flex-end;
  }
}
