@font-face { font-family: "IBM Plex Sans"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Regular.ttf');
} 

@font-face { font-family: "IBM Plex Bold"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Bold.ttf');
} 

@font-face { font-family: "IBM Plex Sans Italic"; 
  src: url('../../assets/font/IBM/IBMPlexSans-LightItalic.ttf');
} 

@font-face { font-family: "IBM Plex Sans medium"; 
  src: url('../../assets/font/IBM/IBMPlexSans-MediumItalic.ttf');
}

.containerTelaConf {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 2em auto;
  max-width: 1400px;
}

.dTotalizador{
  display: none;
}

.BoxCard {
  background-color: #FFFFFF;
  font-family: "IBM Plex Sans medium";
  padding: 0 !important;
  width: 98%;
  min-height: 100px;
  margin-left: 1%;
  height: auto;
}

.Title {
  font-family: "IBM Plex Bold";
}

.Titlesub {
  font-family: "IBM Plex Bold";
  font-size: 14pt;
}

.Titlesub2 {
  font-family: "IBM Plex Bold";
  font-size: 12pt;
}

.TitlesubVal {
  font-family: "IBM Plex Bold";
  font-size: 12pt;
  text-align: right;
}

.TitleInfo {
  font-family: "IBM Plex Sans Italic";
  font-size: 12pt;
}

.TitleInfoVal {
  font-family: "IBM Plex Sans Italic";
  font-size: 12pt;
  text-align: right;
}

.editar {
  color: "#41895E";
  text-align: center;
}

@media (max-width: 600px) {
  .containerTelaConf {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    min-height: 180vh;
    height: auto;
    margin: 0 auto;
  }
}


@media (max-width: 1380px) {
  .containerTelaConfHome {
    margin: 1rem 5rem 0 5rem;
  }
}

@media (min-width: 1450px) {
  .containerTelaConfHome {
    margin: 1rem 5rem 0 5rem;
  }

  .containerTelaConf {
    margin: 2em 0;
    width: 100%;
  }

  .ContConfirmar{
    margin-right: 10px;
    margin-left: 110px;
    max-width: 2100px;
    width: 1650px;
  }
}

@media (max-width: 550px) {
  .containerTelaConf {
    display: flex;
    justify-content: center;
    width: auto;
    min-height: 150vh
  }

  .Btn{
    justify-content: center !important;
    margin-bottom: 10px;
  }

  .resumo{
    display: flex;
    justify-content: center;
  }

  .TitleInfoVal{
    text-align: left;
  }

  .dTotalizador{
    display: block;
  }
}