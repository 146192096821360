.containerTabelaHome {
  background-color: #ffffff;
  border-radius: 5px;
  max-width: 1360px;
  padding: 1.5rem;
  margin-top: 1rem;
}
.containerTabelaHome .containerHomeTabela {
  width: 100%;
  min-height: 250px;
}

tr:nth-child(even) {
  background-color: #f0f0f0;
}

.containerTabelaHome .containerHomeTabela table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
}
.containerTabelaHome .containerHomeTabela table th {
  padding: 10px 8px;
  color: #525252;
  font-size: 1.2rem;
  font-weight: 600;
}
.containerTabelaHome .containerHomeTabela .tabelaFooter {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 0.5rem;
}
@media (max-width: 1366px) {
  .containerTabelaHome {
    width: 95%;
    place-self: center;
  }
}
@media (max-width: 1000px) {
  .containerTabelaHome {
    width: 95%;
    overflow-x: auto;
  }
  .containerTabelaHome .containerHomeTabela table th {
    font-size: 1rem;
  }
}

@media (min-width: 1450px) {
  .containerTabelaHome {
    max-width: 2100px;
    width: 1625px;
  }
}