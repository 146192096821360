@font-face { font-family: "IBM Plex Sans"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Bold.ttf');
} 

@font-face { font-family: "IBM Plex Sans Italic"; 
  src: url('../../assets/font/IBM/IBMPlexSans-LightItalic.ttf');
} 

.subheader {
  width: 100%;
  top: 75px;
  height: 40px;
  background-color: #41895E;
  z-index: 996;
}

.subheader .alignment {
  max-width: 1360px;
  height: 100%;

  margin: 0 auto;

  display: flex;
  align-items: center;
  justify-content: space-between;

  column-gap: 1rem;
  padding: 0 6rem;
}

.subheader .alignment img {
  width: 20px;
  margin-right: 5px;
}

.subheader .alignment h1 {
  font-family: "IBM Plex Sans Italic";
  font-weight: 400;
  font-size: 1.20rem;
  color: #fff;

  margin: 0;
}

.eu{
  display: inherit;
  align-items: inherit;
}

@media(min-width: 1860px){
  .subheader .alignment {
    max-width: 1860px;
    padding: 0 7.5em;
  }
}

@media (max-width: 550px) {
  .subheader .alignment {
    padding: 0 2em;
  }

  .subheader .alignment h1 {
    font-size: 0.8rem;
  }
}