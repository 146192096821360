.containerTelaCad {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  margin: 1rem 8rem 0 8rem;
}

@media (max-width: 800px) {
  .containerTelaCad {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    min-height: 180vh;
    height: auto;
    margin: 1rem 4rem 0 4rem;
  }
}

@media (max-width: 1366px) {
  .containerTelaCad {
    margin: 1rem 6rem 0 6rem;
  }
}

@media (max-width: 550px) {
  .containerTelaCad {
    margin: 1rem 2rem 0 2rem;
    display: block;
  }
}