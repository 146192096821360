@font-face { font-family: "IBM Plex Sans"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Regular.ttf');
} 

@font-face { font-family: "IBM Plex Bold"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Bold.ttf');
} 

@font-face { font-family: "IBM Plex Sans Italic"; 
  src: url('../../assets/font/IBM/IBMPlexSans-LightItalic.ttf');
} 

.corpoTable {
    font-family: "IBM Plex San";
}

.corpoTable tr td {
  border: none;
}

.table tr th {
  font-weight: bold;
}

thead {
  background-color: #D9D9D9;
  color: #424242;
}

.linhasTabela{
  text-align: right;
}