@font-face { font-family: "IBM Plex Sans"; 
  src: url('../../assets/font/IBM/IBMPlexSans-Bold.ttf');
} 

@font-face { font-family: "IBM Plex Sans Italic"; 
  src: url('../../assets/font/IBM/IBMPlexSans-LightItalic.ttf');
} 

.containerCard {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background-color: #41895E;
  color: #fff;
  height: 120px;

  flex-wrap: wrap;
}
.containerCard .infoCard h4 {
  color: #fff;
  font-size: 1rem; 
  font-family: "IBM Plex Sans Italic";
  letter-spacing: 1px;
}
.containerCard .infoCard p {
  color: #fff;
  font-size: 0.9rem;
}

.containerCard .infoCard h2 {
  color: #fff;
  font-size: 1.5rem;
  margin-bottom: 0;
  font-family: 'IBM Plex Sans';
  font-style: normal;
}
.containerCard .iconeCard {
  color: #fff;
  width: 60px;
  height: 50px;
}
.containerCard .iconeCard svg {
  height: 100%;
  width: 100%;
  color: #fff;
}
@media (max-width: 400px) {
  .containerCard {
    padding: 1rem 1rem;
    height: 120px;
  }
  .containerCard .infoCard h4 {
    font-size: 1rem;
  }
  .containerCard .infoCard h2 {
    font-size: 1.5rem;
  }
  .containerCard .iconeCard {
    width: 55px;
    height: 45px;
  }
}
