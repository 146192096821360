@media (max-width: 550px) {
    .dNome {
      margin-top: 10px;
      margin-bottom: 10px;
    }
    
    .btnConsultar{
        display: flex;
        justify-content: flex-end;
    }

    .modalMob{
      width: '100%'
    }
  }

  .modalMob{
    max-width: '950px';
    width: '100%'
  }